import authActions from '../actions/authenticated';
import resetActions from '../actions/reset';

const initialState = {
  auth: true
}

const onlineReducer = (state = initialState, action) => {
  switch(action.type) {
    case authActions.SET_AUTH:
      return { ...state, auth: action.payload };
    case resetActions.RESET_ALL:
      return initialState;
    default:
      return state;
  }
}

export default onlineReducer;