import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import Confetti from 'react-confetti';
import { Modal, Button } from 'react-bootstrap';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Select, { components } from 'react-select';
import actions from '../../redux/actions';
import fpAnniversaryLogo from '../../images/anniversary.png';
import googleButton from '../../images/google-icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import TagManager from 'react-gtm-module';
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive';
import { countryOptions, groupedOptions } from '../../data/countries';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';

const Login = () => {
  const user = useSelector((state) => state.user.user);
  const currentLanguage = useSelector((state) => state.user.currentLanguage);
  const { register, handleSubmit } = useForm();
  const [createAccountMessage, setCreateAccountMessage] =
    useState('Create Account');
  const [loginButtonMessage, setLoginButtonMessage] = useState('Log in');
  const [showReset, setShowReset] = useState(false);
  const [showCodeConfirm, setShowCodeConfirm] = useState(false);
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [showNewAccountConfirm, setShowNewAccountConfirm] = useState(false);
  const [showConfirmAccount, setShowConfirmAccount] = useState(false);
  const [newAccountPassword, setNewAccountPassword] = useState();
  const [showSignupText, setShowSignupText] = useState(false);
  const [showLoginText, setShowLoginText] = useState(true);
  const [showResetPasswordText, setShowResetPasswordText] = useState(false);
  const [showAnniversaryMessage, setShowAnniversaryMessage] = useState(false);
  const [email, setEmail] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const search = useLocation().search;
  const [loginError, setErrorMessage] = useState();
  const auth = useSelector((state) => state.authenticated.auth);
  const browserLocale =
    navigator.language === undefined ? 'en' : navigator.language.slice(0, 2);
  const languageSet = useSelector((state) => state.user.languageSet);
  const { addToast } = useToasts();
  const [welcomeLabel, setWelcomeLabel] = useState('Welcome back!');
  const [loginLabel, setLoginLabel] = useState('Log in to your account.');
  const [signupOneLabel, setSignUpOneLabel] = useState('Sign up now');
  const [signupTwoLabel, setSignUpTwoLabel] = useState('and use our');
  const [signupThreeLabel, setSignUpThreeLabel] = useState(
    'interactive scavenger hunt'
  );
  const [signupFourLabel, setSignUpFourLabel] = useState(
    'to learn key features of the platform.'
  );
  const [emailLabel, setEmailLabel] = useState('Email');
  const [codeConfirmLabel, setCodeConfirmLabel] = useState(
    'Enter confirmation code sent to your email'
  );
  const [passReqLabel, setPassReqLabel] = useState(
    'Your password must be at least 6 characters.'
  );
  const [passwordLabel, setPasswordLabel] = useState('Password');
  const [confirmPasswordLabel, setConfirmPasswordLabel] =
    useState('Confirm Password');
  const [forgotPasswordLabel, setForgotPasswordLabel] = useState(
    'Forgot your Password?'
  );
  const [haveAccountLabel, setHaveAccountLabel] = useState(
    'Already have an account?'
  );
  const [loginShortLabel, setLoginShortLabel] = useState('Log in');
  const [resetPasswordLabel, setResetPasswordLabel] =
    useState('Reset Password');
  const [notOnLabel, setNotOnLabel] = useState('Not on FP insight yet?');
  const [signUpLabel, setSignUpLabel] = useState('Sign up');
  const [googleLabel, setGoogleLabel] = useState('Continue with Google');
  const [fbLabel, setFbLabel] = useState('Continue with Facebook');
  const [agreeLabel, setAgreeLabel] = useState('By signing up, you agree to');
  const [guidelinesLabel, setGuidelinesLabel] = useState(
    'Community Guidelines'
  );
  const [termsLabel, setTermsLabel] = useState('Terms of Use');
  const [privacyLabel, setPrivacyLabel] = useState('Privacy Policy');
  const [sendCodeLabel, setSendCodeLabel] = useState('Send Code');
  const [resendCodeLabel, setResendCodeLabel] = useState('Resend Code');
  const [submitLabel, setSubmitLabel] = useState('Submit');
  const [capsLabel, setCapsLabel] = useState('Caps lock is on');
  const [countryLabel, setCountryLabel] = useState('Country where you live?');
  const [requiredLabel, setRequiredLabel] = useState('required');
  const loginType = new URLSearchParams(search).get('login');
  const [countryError, setCountryError] = useState(false);
  const [updatedCountry, setUpdatedCountry] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [firstNameLabel, setFirstNameLabel] = useState('First Name');
  const [lastNameLabel, setLastNameLabel] = useState('Last Name');
  const [requiredValuesLabel, setRequiredValuesLabel] = useState(
    'First Name, Last Name and Country are required.'
  );
  const inviteCode = new URLSearchParams(search).get('inviteCode');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const groupStyles = {
    border: `1px`,
    borderRadius: '1px',
    background: '#f2fcff',
  };

  const Group = (props) => (
    <div style={groupStyles}>
      <components.Group {...props} />
    </div>
  );
  const setCountry = async (selected) => {
    setUpdatedCountry(selected.value);
  };

  React.useEffect(() => {
    // console.log('use effect');
    if (loginType === 'false') {
      console.log('initial login');
      initCreateAccount();
      return;
    }
    showSignIn();
  }, [loginType]);

  React.useEffect(() => {
    // if the href includes login=false, then don't show the login
    if (
      window.location.href.includes('login=false') ||
      window.location.href.includes('SignUp')
    ) {
      setShowAnniversaryMessage(false);
    }
  }, [location]);

  React.useEffect(() => {
    const loginType = new URLSearchParams(search).get('login');
    translateLabels(currentLanguage, loginType === 'false' ? false : true);
  }, [currentLanguage]);

  React.useEffect(() => {
    return onAuthUIStateChange(async (newAuthState, authData) => {
      if (newAuthState === 'signedin') {
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${authData.signInUserSession.getIdToken().getJwtToken()}`;
        localStorage.setItem('isSystemUser', true);

        if (!languageSet) {
          dispatch(actions.userActions.setLanguage(browserLocale));
        }
      }
    });
  }, [dispatch, history]);

  const translateLabels = async (language, login) => {
    try {
      if (!language || language === 'en') {
        setLoginLabel('Log in to your account.');
        setWelcomeLabel('Welcome back!');
        setSignUpOneLabel('Sign up now');
        setSignUpTwoLabel('and use our');
        setSignUpThreeLabel('interactive scavenger hunt');
        setSignUpFourLabel('to learn key features of the platform.');
        setEmailLabel('Email');
        setCodeConfirmLabel('Enter confirmation code sent to your email');
        setPassReqLabel('Your password must be at least 6 characters.');
        setPasswordLabel('Password');
        setConfirmPasswordLabel('Confirm Password');
        setForgotPasswordLabel('Forgot your Password?');
        setHaveAccountLabel('Already have an account?');
        setLoginShortLabel('Log in');
        setResetPasswordLabel('Reset Password');
        setNotOnLabel('Not on FP insight yet?');
        setSignUpLabel('Sign up');
        setGoogleLabel('Continue with Google');
        setFbLabel('Continue with Facebook');
        setAgreeLabel('By continuing, you agree to');
        setGuidelinesLabel('Community Guidelines');
        setTermsLabel('Terms of Use');
        setPrivacyLabel('Privacy Policy');
        setSendCodeLabel('Send Code');
        setResendCodeLabel('Resend Code');
        setSubmitLabel('Submit');
        if (login) {
          setLoginButtonMessage('Log in');
        } else {
          setLoginButtonMessage('Create Account');
        }
        //setLoginButtonMessage('Log in');
        setCreateAccountMessage('Create Account');
        setCapsLabel('Caps lock is on');
        setCountryLabel('Country where you live?');
        setFirstNameLabel('First Name');
        setLastNameLabel('Last Name');
        setRequiredValuesLabel(
          'First Name, Last Name and Country are required.'
        );
        setRequiredLabel('required');
        return;
      } else {
        const sections = await axios.post(
          `${process.env.REACT_APP_API_BASE}/admin/translateText`,
          {
            currentLanguage: currentLanguage,
            text: [
              loginLabel,
              signupOneLabel,
              signupTwoLabel,
              signupThreeLabel,
              signupFourLabel,
              emailLabel,
              codeConfirmLabel,
              passReqLabel,
              passwordLabel,
              forgotPasswordLabel,
              haveAccountLabel,
              loginShortLabel,
              resetPasswordLabel,
              notOnLabel,
              signUpLabel,
              googleLabel,
              fbLabel,
              agreeLabel,
              guidelinesLabel,
              termsLabel,
              privacyLabel,
              sendCodeLabel,
              resendCodeLabel,
              submitLabel,
              loginButtonMessage,
              createAccountMessage,
              welcomeLabel,
              confirmPasswordLabel,
              capsLabel,
              countryLabel,
              firstNameLabel,
              lastNameLabel,
              requiredValuesLabel,
              requiredLabel,
            ],
            type: 'text',
            objectType: 'array',
          }
        );

        if (sections.data && sections.data.length === 34) {
          setLoginLabel(sections.data[0]);
          setSignUpOneLabel(sections.data[1]);
          setSignUpTwoLabel(sections.data[2]);
          setSignUpThreeLabel(sections.data[3]);
          setSignUpFourLabel(sections.data[4]);
          setEmailLabel(sections.data[5]);
          setCodeConfirmLabel(sections.data[6]);
          setPassReqLabel(sections.data[7]);
          setPasswordLabel(sections.data[8]);
          setForgotPasswordLabel(sections.data[9]);
          setHaveAccountLabel(sections.data[10]);
          setLoginShortLabel(sections.data[11]);
          setResetPasswordLabel(sections.data[12]);
          setNotOnLabel(sections.data[13]);
          setSignUpLabel(sections.data[14]);
          setGoogleLabel(sections.data[15]);
          setFbLabel(sections.data[16]);
          setAgreeLabel(sections.data[17]);
          setGuidelinesLabel(sections.data[18]);
          setTermsLabel(sections.data[19]);
          setPrivacyLabel(sections.data[20]);
          setSendCodeLabel(sections.data[21]);
          setResendCodeLabel(sections.data[22]);
          setSubmitLabel(sections.data[23]);
          if (login) {
            setLoginButtonMessage(sections.data[24]);
          } else {
            setLoginButtonMessage(sections.data[25]);
          }
          //setLoginButtonMessage(sections.data[24])
          setCreateAccountMessage(sections.data[25]);
          setWelcomeLabel(sections.data[26]);
          setConfirmPasswordLabel(sections.data[27]);
          setCapsLabel(sections.data[28]);
          setCountryLabel(sections.data[29]);
          setFirstNameLabel(sections.data[30]);
          setLastNameLabel(sections.data[31]);
          setRequiredValuesLabel(sections.data[32]);
          setRequiredLabel(sections.data[33]);
        }
      }
    } catch {
      addToast(`There was an error translating the quick links`, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 10000,
      });
    }
  };

  const login = async (login) => {
    try {
      setUpdating(true);
      setCountryError(false);
      setErrorMessage(null);
      console.log(`login: ${JSON.stringify(login)}`);

      if (showConfirmAccount) {
        console.log(`login: showConfirmAccount`);
        let newAccount = await Auth.confirmSignUp(
          login.email,
          login.newAccountCode
        );
        if (newAccount === 'SUCCESS') {
          console.log('account confirmed');
          addToast(
            'Your account has been confirmed, you can now request a password reset.',
            {
              appearance: 'success',
              autoDismiss: true,
              autoDismissTimeout: 5000,
            }
          );

          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
        setUpdating(false);
      } else if (showNewAccountConfirm) {
        let newAccount = await Auth.confirmSignUp(
          login.email,
          login.newAccountCode
        );
        if (newAccount === 'SUCCESS') {
          let signedInUser = await Auth.signIn(
            login.email,
            newAccountPassword ? newAccountPassword : login.password
          );

          // send a gtm event for account creation.
          TagManager.dataLayer({
            dataLayer: {
              event: 'UserRegistration',
            },
          });

          const usr = {
            username: signedInUser.attributes.sub,
            email: signedInUser.attributes.email,
            firstName: firstName,
            lastName: lastName,
            country: updatedCountry,
          };

          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE}/user`,
            usr
          );
          const userResponse = response.data;
          dispatch(actions.userActions.setUser(userResponse));
          setLoginButtonMessage(loginShortLabel);
          setShowNewAccountConfirm(false);
          history.push(`/SignUp`);
        }
        setUpdating(false);
      } else if (showCodeConfirm) {
        console.log(`login: showCodeConfirm`);
        setFirstName(login.newAccountFirstName);
        setLastName(login.newAccountLastName);
        if (login.newAccountPassword !== login.confirmNewAccountPassword) {
          setErrorMessage('Passwords do not match.');
          setUpdating(false);
          return;
        } else setErrorMessage(null);

        if (login.passwordCode === '') {
          setErrorMessage('Enter the code from the email.');
          return;
        } else setErrorMessage(null);
        console.log('setNewAccountPassword');
        setNewAccountPassword(login.newAccountPassword);
        console.log('send forgotPasswordSubmit 2');
        const resetPassword = await Auth.forgotPasswordSubmit(
          login.email,
          login.passwordCode,
          login.newAccountPassword
        );
        console.log(resetPassword);
        setLoginButtonMessage(loginShortLabel);
        setShowCodeConfirm(false);
        setShowReset(true);
        setUpdating(false);
      } else if (!showCreateAccount) {
        console.log(`login: !showCreateAccount`);
        setFirstName(login.newAccountFirstName);
        setLastName(login.newAccountLastName);
        if (!login.email) {
          setErrorMessage(
            'Enter an email address and password first. Then click on Create Account.'
          );
          setUpdating(false);
          return;
        }

        if (!login.newAccountPassword) {
          setErrorMessage('Password required.');
          setUpdating(false);
          return;
        } else setErrorMessage(null);

        if (login.newAccountPassword !== login.confirmNewAccountPassword) {
          setErrorMessage('Passwords do not match.');
          setUpdating(false);
          return;
        } else setErrorMessage(null);

        if (
          login.newAccountFirstName === '' ||
          login.newAccountLastName === '' ||
          !updatedCountry
        ) {
          setErrorMessage(requiredValuesLabel);
          setCountryError(true);
          setUpdating(false);
          return;
        }

        // before allowing signup, see if the domain is restricted
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE}/user/emailHasBlockedUrl`,
          { email: login.email }
        );
        if (response && response.data && response.data.valid) {
          await Auth.signUp({
            username: login.email,
            password: login.newAccountPassword,
          });
          setShowNewAccountConfirm(true);
          setFirstName(login.newAccountFirstName);
          setLastName(login.newAccountLastName);
          setNewAccountPassword(login.newAccountPassword);
          setLoginButtonMessage('Confirm Account');
        } else {
          setErrorMessage('Invalid Email Domain');
        }
        setUpdating(false);
      } else if (!showReset) {
        console.log(`login: !showReset`);
        setLoginButtonMessage('Requesting Reset Code...');
        console.log('send forgotPasswordSubmit 1');
        const resetPassword = await Auth.forgotPassword(login.email);
        console.log(`resetpassword: ${resetPassword}`);
        setShowCodeConfirm(true);
        setLoginButtonMessage('Confirm Code');
        setUpdating(false);
      } else {
        console.log(`login: else`);
        setLoginButtonMessage('Logging In...');
        let signedInUser = await Auth.signIn(login.email, login.password);

        const usr = {
          username: signedInUser.attributes.sub,
          email: signedInUser.attributes.email,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE}/user`,
          usr
        );
        const userResponse = response.data;
        if (userResponse.status === 2) {
          Auth.signOut();
          setErrorMessage('Your user has been blocked');
          return;
        }

        // // // send a gtm event for sign in.
        TagManager.dataLayer({
          dataLayer: {
            event: 'UserSignIn',
          },
        });

        dispatch(actions.userActions.setUser(userResponse));
        window.scrollTo(0, 0);
        showSignIn();
        setUpdating(false);

        // if there is an inviteCode get the data needed to forward the user directly to the collection they were invited to
        const invitationCode = localStorage.getItem('inviteCode');
        if (invitationCode) {
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE}/collections/inviteCollaborator`,
            { type: 'getCollectionId', collabId: invitationCode }
          );
          if (response && response.data && response.data.collectionid) {
            console.log('go to the collection');
            history.push(
              `/Collection/${response.data.collectionid}?invitationpending=true`
            );
          } else {
            window.location.reload();
          }
        } else {
          setTimeout(() => {
            console.log('logged in reload');
            console.log(`inviteCode: ${localStorage.getItem('inviteCode')}`);
            window.location.reload();
          }, 50);
        }
      }
    } catch (error) {
      // {"code":"UserNotConfirmedException","name":"UserNotConfirmedException","message":"User is not confirmed."}
      if (error && error.code && error.code === 'UserNotConfirmedException') {
        console.log('user not confirmed exception');
        setShowNewAccountConfirm(true);
        setLoginButtonMessage(loginShortLabel);
      } else if (
        error &&
        error.code &&
        error.code === 'UsernameExistsException'
      ) {
        setErrorMessage(error.message);
        setLoginButtonMessage(createAccountMessage);
      } else if (
        error.message ===
        'Cannot reset password for the user as there is no registered/verified email or phone_number'
      ) {
        console.log('reset password attempt on unverified user');
        await Auth.resendSignUp(login.email);
        setErrorMessage(
          'Your account is unverified, please check your email for a verification link.'
        );
        setShowConfirmAccount(true);
        setShowNewAccountConfirm(true);
        setLoginButtonMessage('Confirm Account');
      } else if (
        error.message === 'Username/client id combination not found.'
      ) {
        setErrorMessage(
          "Your email account does not exist, click on 'Sign Up' to create an account."
        );
      } else {
        setErrorMessage(error.message);
        console.log(
          `error.code: ${error.code} login: ${JSON.stringify(login)}`
        );
        if (
          error.code === 'CodeMismatchException' ||
          error.message === 'Confirmation code cannot be empty'
        ) {
          setLoginButtonMessage('Confirm Code');
        } else {
          setLoginButtonMessage(loginShortLabel);
        }
      }
      setUpdating(false);
    }
  };

  const resendCode = async () => {
    await Auth.resendSignUp(email);
    addToast('Your code has been resent.', {
      appearance: 'success',
      autoDismiss: true,
      autoDismissTimeout: 10000,
    });
  };

  const showResetPassword = async () => {
    setErrorMessage(null);
    setShowReset(false);
    setShowResetPasswordText(true);
    setShowLoginText(false);
    setShowSignupText(false);
    setLoginButtonMessage(sendCodeLabel);
  };
  const showSignIn = async () => {
    setErrorMessage(null);
    setShowReset(true);
    setShowLoginText(true);
    setShowResetPasswordText(false);
    setShowSignupText(false);
    setShowCodeConfirm(false);
    setShowCreateAccount(true);
    setLoginButtonMessage(loginShortLabel);
    setShowNewAccountConfirm(false);
  };
  const initCreateAccount = async () => {
    console.log(`set inviteCode: ${inviteCode}`);
    if (inviteCode) {
      localStorage.setItem('inviteCode', inviteCode);
    }
    setErrorMessage(null);
    setShowLoginText(false);
    setShowResetPasswordText(false);
    setShowLoginText(false);
    setShowSignupText(true);
    setLoginButtonMessage(createAccountMessage);
    setShowCreateAccount(false);
    setShowReset(false);
    setShowNewAccountConfirm(false);
  };

  const cancelAnniversaryMessage = () => setShowAnniversaryMessage(false);

  return user && auth ? null : (
    <div className="col">
      {showSignupText && (
        <div className="mb-3 mr-3 ml-3">
          &nbsp;
          <div className="sh-callout text-center">
            <strong>{signupOneLabel}</strong> {signupTwoLabel}
            <a
              className="sh-callout-link"
              href="fpinsight-scavenger-hunt/english.html"
              target="new"
            >
              {' '}
              {signupThreeLabel}
            </a>{' '}
            {signupFourLabel}
          </div>
        </div>
      )}

      {showLoginText && (
        <div className="form-label mt-3 d-flex align-items-center justify-content-center pt-3">
          <h2>{welcomeLabel}</h2>
        </div>
      )}
      {showLoginText && (
        <div className="form-label d-flex align-items-center justify-content-center">
          {loginLabel}
        </div>
      )}

      {showResetPasswordText && (
        <div className="mt-4">
          <h2>Reset Password</h2>
        </div>
      )}

      <form onSubmit={handleSubmit(login)} noValidate>
        {!showCreateAccount && !showNewAccountConfirm && (
          <div className="col-lg-12 col-sm-12">
            <div className="row d-flex justify-content-center">
              <div className="col ml-3">
                <label htmlFor="newAccountFirstName" className="form-label">
                  {firstNameLabel}{' '}
                  <span className="required">({requiredLabel})</span>
                </label>
                <input
                  type="text"
                  required
                  id="newAccountFirstName"
                  name="newAccountFirstName"
                  className="form-control mb-1"
                  ref={register}
                  aria-describedby="password_helper"
                />
              </div>
              <div className="col mr-3">
                <label htmlFor="newAccountLastName" className="form-label">
                  {lastNameLabel}{' '}
                  <span className="required">({requiredLabel})</span>
                </label>
                <input
                  type="text"
                  required
                  id="newAccountLastName"
                  name="newAccountLastName"
                  className="form-control mb-1"
                  ref={register}
                  aria-describedby="password_helper"
                />
              </div>
            </div>
            <div className="mb-2">
              <label htmlFor="country" className="form-label">
                {countryLabel}{' '}
                <span className="required">({requiredLabel})</span>
              </label>
              <Select
                id="country"
                aria-invalid={countryError ? 'true' : 'false'}
                aria-label={'select country'}
                onChange={setCountry}
                defaultValue={
                  updatedCountry
                    ? countryOptions.filter(function (x) {
                        return x.value === updatedCountry;
                      })[0]
                    : countryOptions[0]
                }
                options={groupedOptions}
                components={{ Group }}
              />
              {countryError ? (
                <span
                  className="alert-label"
                  id="errorMessage"
                  aria-hidden="false"
                  role="alert"
                >
                  {countryLabel} {requiredLabel}
                </span>
              ) : null}
            </div>
          </div>
        )}

        <div className="ml-3 mr-3">
          <label htmlFor="email" className="form-label">
            {emailLabel}
          </label>
          <input
            id="email"
            required
            name="email"
            type="email"
            className="form-control"
            onChange={(e) => setEmail(e.target.value)}
            ref={register}
          />
        </div>
        {showNewAccountConfirm && (
          <div className="ml-3 mr-3">
            <label htmlFor="newAccountCode" className="form-label">
              {codeConfirmLabel}
            </label>
            <input
              id="newAccountCode"
              required
              name="newAccountCode"
              type="number"
              className="form-control"
              ref={register}
            />
            <a href={null} onClick={resendCode}>
              {resendCodeLabel}
            </a>
          </div>
        )}
        {showCodeConfirm && (
          <div className="ml-3 mr-3">
            <label htmlFor="passwordCode" className="form-label">
              {codeConfirmLabel}
            </label>
            <input
              id="passwordCode"
              required
              name="passwordCode"
              className="form-control"
              ref={register}
            />
            <button
              className="btn btn-secondary btn-sm mt-2 mb-2"
              type="button"
              onClick={resendCode}
            >
              {resendCodeLabel}
            </button>
            <br />
            <ReactIsCapsLockActive>
              {(active) => (
                <span className="alert-label">{active ? capsLabel : ''}</span>
              )}
            </ReactIsCapsLockActive>
            <br />
            <label htmlFor="newPassword" className="form-label">
              New Password
            </label>
            <button
              onClick={() => setShowPassword(!showPassword)}
              className="btn ml-2"
            >
              {showPasswordConfirm ? (
                <FontAwesomeIcon icon={faEye} />
              ) : (
                <FontAwesomeIcon icon={faEyeSlash} />
              )}
            </button>
            <input
              type={showPasswordConfirm ? 'text' : 'password'}
              required
              id="newAccountPassword"
              name="newAccountPassword"
              className="form-control mb-1"
              ref={register}
              aria-describedby="password_helper"
            />
            <label htmlFor="confirmNewAccountPassword" className="form-label">
              {confirmPasswordLabel}
            </label>

            <input
              type={showPasswordConfirm ? 'text' : 'password'}
              required
              id="confirmNewAccountPassword"
              name="confirmNewAccountPassword"
              className="form-control mb-1"
              ref={register}
              aria-describedby="password_helper"
            />
            <span id="password_helper" className="password-helper-text">
              {passReqLabel}
            </span>
          </div>
        )}

        {!showCreateAccount && !showNewAccountConfirm && (
          <div className="ml-3 mr-3">
            <ReactIsCapsLockActive>
              {(active) => (
                <span className="alert-label">{active ? capsLabel : ''}</span>
              )}
            </ReactIsCapsLockActive>
            <br />
            <label htmlFor="newAccountPassword" className="form-label">
              {passwordLabel}
            </label>
            <button
              className="btn ml-2"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <>
                  <FontAwesomeIcon icon={faEye} />
                </>
              ) : (
                <FontAwesomeIcon icon={faEyeSlash} />
              )}
            </button>
            <input
              type={showPassword ? 'text' : 'password'}
              required
              id="newAccountPassword"
              name="newAccountPassword"
              className="form-control mb-1"
              ref={register}
              aria-describedby="password_helper"
            />
            <label htmlFor="confirmNewAccountPassword" className="form-label">
              {confirmPasswordLabel}
            </label>
            <button
              onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
              className="btn ml-2"
            >
              {showPasswordConfirm ? (
                <FontAwesomeIcon icon={faEye} />
              ) : (
                <FontAwesomeIcon icon={faEyeSlash} />
              )}
            </button>
            <input
              type={showPasswordConfirm ? 'text' : 'password'}
              required
              id="confirmNewAccountPassword"
              name="confirmNewAccountPassword"
              className="form-control mb-1"
              ref={register}
              aria-describedby="password_helper"
            />
            <span id="password_helper" className="password-helper-text">
              {passReqLabel}
            </span>
          </div>
        )}
        {showReset && (
          <div className="ml-3 mr-3">
            <ReactIsCapsLockActive>
              {(active) => (
                <span className="alert-label">{active ? capsLabel : ''}</span>
              )}
            </ReactIsCapsLockActive>
            <br />
            <label htmlFor="password" className="form-label">
              {passwordLabel}
            </label>
            <span
              onClick={() => setShowPassword(!showPassword)}
              className="btn ml-2"
            >
              {showPassword ? (
                <FontAwesomeIcon icon={faEye} />
              ) : (
                <FontAwesomeIcon icon={faEyeSlash} />
              )}
            </span>
            <input
              type={showPassword ? 'text' : 'password'}
              required
              id="password"
              name="password"
              className="form-control"
              ref={register}
            />
          </div>
        )}
        {loginError ? (
          <div className="ml-3 mr-3">
            <span
              className="alert-label"
              id="errorMessage"
              aria-hidden="false"
              role="alert"
            >
              {loginError}
            </span>
          </div>
        ) : (
          <div className="ml-3 mr-3">
            <span
              className="alert-label"
              id="errorMessage"
              aria-hidden="true"
              role="alert"
            >
              {loginError}
            </span>
          </div>
        )}

        <div className="mt-3 ml-3 mr-3">
          {updating ? (
            <button
              className="btn btn-primary btn-block"
              disabled
              type="submit"
            >
              <FontAwesomeIcon icon={['fal', 'spinner']} color="#00709B" spin />
              {loginButtonMessage}
            </button>
          ) : (
            <button className="btn btn-primary btn-block" type="submit">
              {loginButtonMessage}
            </button>
          )}
        </div>

        {/* {!showReset && (
          <div className="">
            {haveAccountLabel} <a href="#" onClick={showSignIn}>{loginShortLabel}</a>
          </div>
          )} */}
        {showReset && (
          <div className="ml-3 mr-3">
            <label htmlFor="forgotPasswordLink" className="form-label mt-3">
              {forgotPasswordLabel}{' '}
            </label>
            <br />
            <a href="#" id="forgotPasswordLink" onClick={showResetPassword}>
              {' '}
              {resetPasswordLabel}
            </a>
          </div>
        )}
      </form>

      <div className="d-flex align-items-center justify-content-center mt-3">
        <strong>Or</strong>
      </div>
      <div className="ml-3 mr-3 mt-3">
        <button
          id="googleSignIn"
          className="btn btn-block w-100 btn-primary google-btn d-flex align-items-center justify-content-center google-height"
          onClick={() => Auth.federatedSignIn({ provider: 'Google' })}
        >
          <img src={googleButton} alt="Login With Google" />{' '}
          <span>{googleLabel}</span>
        </button>
      </div>

      <div className="ml-3 mr-3 mt-3">
        <button
          id="facebookSignIn"
          className="btn btn-block btn-primary facebook-btn d-flex align-items-center justify-content-center"
          onClick={() => Auth.federatedSignIn({ provider: 'Facebook' })}
        >
          <FontAwesomeIcon
            icon={['fab', 'facebook-square']}
            className="fa-2x mr-2"
          />
          <span>{fbLabel}</span>
        </button>
      </div>
      <div className="ml-3 mr-3 mt-3">
        {agreeLabel} FP insight’s <a href="/guidelines">{guidelinesLabel}</a>,{' '}
        <a href="/terms">{termsLabel}</a>, and{' '}
        <a href="/privacy-policy">{privacyLabel}</a>.
      </div>
      <hr />
      {!showReset && (
        <div className="d-flex justify-content-center align-items-center">
          <div className="form-label ml-3 mr-3 mb-3">
            {haveAccountLabel}{' '}
            <a href="#" onClick={() => history.push('/?login=true')}>
              {loginShortLabel}
            </a>
          </div>
        </div>
      )}
      {showCreateAccount && (
        <div className="d-flex justify-content-center align-items-center">
          <div className="form-label ml-3 mr-3 mb-3">
            <label htmlFor="showSignupLink" className="form-label">
              {notOnLabel}{' '}
            </label>
            <a
              href="#"
              className=""
              onClick={() => history.push('/?login=false')}
              id="showSignupLink"
            >
              {' '}
              {signUpLabel}
            </a>
          </div>
        </div>
      )}

      <Modal show={showAnniversaryMessage} centered>
        <Confetti
          width={500}
          height={360}
          opacity={0.5}
          numberOfPieces={100}
          gravity={0.03}
          confettiSource={{ x: -0.5, y: 0, w: 500, h: 0 }}
        />
        <Modal.Header onHide={cancelAnniversaryMessage} closeButton>
          <Modal.Title centered>
            <img
              src={fpAnniversaryLogo}
              alt="FP insights, powered by Knowledge Success"
              className="anniversary-logo centered"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center m-3 h5">
          <p>We’re celebrating our birthday at FP insight!</p>
          Click below to hear from FP/RH professionals across Africa, Asia, and
          the U.S. about <br />
          FP insight’s impact in its first year and what exciting updates to
          look for next.
        </Modal.Body>
        <Modal.Footer className="row">
          <div className="col text-center mb-2 pb-3">
            <Button
              variant="primary"
              href="https://my.knowledgesuccess.org/fp-insight-member-stories"
              target="_blank"
            >
              Click Here
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Login;
