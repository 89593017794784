import parse from 'html-react-parser';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import fpLogo from '../../images/FPinsight-logo.svg';
import line_hero from '../../images/about/line_hero_left_blue.svg';
import line_hero_right from '../../images/about/line_hero_right_blue.svg';
import Notifications from '../notifications/Notifications';
import Avatar from '../user/Avatar';

// add nav test
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkipNavLink } from '@reach/skip-nav';
import '@reach/skip-nav/styles.css';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Navbar from 'react-bootstrap/Navbar';
import Headroom from 'react-headroom';
import { Link, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import useLanguageHistory from '../../hooks/useLanguageHistory';
import actions from '../../redux/actions';
import Login from '../authentication/Login';
import InfoBanner from './InfoBanner';

// ! add nav test
const Header = () => {
  const user = useSelector((state) => state.user.user);
  const [showLogin, setShowLogin] = useState(user ? false : true);
  const [showSignUp, setShowSignUp] = useState(false);
  const dispatch = useDispatch();
  const searchTerm = useSelector((state) => state.search.term);
  const searchType = useSelector((state) => state.search.type);
  const runSearch = useSelector((state) => state.search.runSearch);
  const currentLanguage = useSelector((state) => state.user.currentLanguage);
  const isOnline = useSelector((state) => state.online.isOnline);
  const auth = useSelector((state) => state.authenticated.auth);
  const postType = useSelector((state) => state.posts.type);
  const history = useHistory();
  const location = useLocation();
  const [quickLinkLabel, setQuickLinkLabel] = useState('About');
  const [exploreLabel, setExploreLabel] = useState('Explore');
  const [getHelpLabel, setGetHelpLabel] = useState('Get Help');
  const [getEarnLabel, setGetEarnLabel] = useState('Earn Badges');
  const [extensionLabel, setExtensionLabel] = useState(
    'FP insight Browser Button'
  );
  const [guidelinesLabel, setGuidelinesLabel] = useState(
    'Community Guidelines'
  );
  const [settingsLabel, setSettingsLabel] = useState('Settings');
  const [logoutLabel, setLogoutLabel] = useState('Logout');
  const [loginLabelThree, setLoginLabelThree] = useState(
    'Up for an adventure? Check out our fun and interactive <a href="scavengerhunt" target="_blank" rel="noreferrer" aria-label="scavenger hunt link">scavenger hunt</a> and earn your <a href="/Badges" rel="noreferrer" aria-label="badges link">Explorer badge</a> while learning more about FP insight!'
  );
  const [aboutLabel, setAboutLabel] = useState('About FP insight');
  const [contactLabel, setContactLabel] = useState('Contact Us');
  const [currentLanguageLabel, setCurrentLanguageLabel] = useState('English');
  const [trendingLabel, setTrendingLabel] = useState(
    'See what’s trending in FP/RH programs.'
  );
  const [quicklyOneLabel, setQuicklyOneLabel] = useState(
    'Quickly find relevant and timely information.'
  );
  const [quicklyTwoLabel, setQuicklyTwoLabel] = useState('FP insight');
  const [quicklyThreeLabel, setQuicklyThreeLabel] = useState(
    'makes it easy for you to monitor what’s'
  );
  const [quicklyFourLabel, setQuicklyFourLabel] =
    useState('trending in FP/RH.');
  const [saveOneLabel, setSaveOneLabel] = useState('Save what you need.');
  const [saveTwoLabel, setSaveTwoLabel] = useState(
    "Curate your own collections so it's easy for you to come back to the information you need, when you need it - even when you're offline."
  );
  const [inspireOneLabel, setinspireOneLabel] = useState(
    'See what inspires others.'
  );
  const [inspireTwoLabel, setinspireTwoLabel] = useState('When you use');
  const [inspireThreeLabel, setinspireThreeLabel] = useState(
    'you’re contributing to a body of knowledge that benefits the whole FP/RH professional community.'
  );
  const [fpInsightText, setFpInsightText] = useState(
    'FP insight is simple, user-friendly, and responding to a real need in our field right now.'
  );
  const [fpInsightNumberText, setFpInsightNumberText] = useState(
    'of your FP/RH colleagues are on FP insight.'
  );
  const [fpJoinLink, setFpJoinLink] = useState('Join them today!');
  const [qouteLabel, setQouteLabel] = useState(
    'FP insight is a game-changer for the FP/RH community.  I love it.'
  );
  const [learnMoreLabel, setLearnMoreLabel] = useState('Learn More');
  const [forYouBtnLabel, setForYouBtnLabel] = useState('For You');
  const [trendingBtnLabel, setTrendingBtnLabel] = useState('Trending');
  const [followingBtnLabel, setFollowingBtnLabel] = useState('Following');
  const [allUsersLabel, setAllUsersLabel] = useState(
    'Browse All FP insight Users'
  );
  const [allCollectionsLabel, setAllCollectionsLabel] = useState(
    'Browse All Collections'
  );
  const [searchHelperText, setSearchHelperText] = useState(
    'To Search, Type a word or phrase and then press Enter'
  );
  const [loginText, setLoginText] = useState('Log in');
  const [signUpText, setSignUpText] = useState('Sign up');
  const [newFeaturesRoadmapLabel, setNewFeaturesRoadmapLabel] = useState(
    'New Features Roadmap'
  );
  const [numberOfUsers, setNumberOfUsers] = useState(0);
  const [numberOfUsersLabel, setNumberOfUsersLabel] = useState(
    'of your FP/RH colleagues are using FP insight! <a href="/browse" rel="noreferrer" aria-label="browse all users link">Browse</a> or search for specific individuals and join them now if you don’t already have an account'
  );
  const [scavengerHunt, setScavengerHunt] = useState(
    'FP insight Scavenger Hunt'
  );
  const [userTestimonials, setUserTestimonials] = useState(
    'FP insight Member Stories'
  );
  const [fpInsightLinkMore, setFpInsightLinkMore] = useState(
    'Learn More about FP insight'
  );
  const [exploreLink, setExploreLink] = useState(
    "Explore FP insight's features"
  );
  const [memberStoriesLink, setMemberStoriesLink] = useState(
    'Read FP insight member stories'
  );

  const { addToast } = useToasts();

  React.useEffect(() => {
    // if the href includes login=false, then don't show the login
    if (
      window.location.href.includes('login=false') ||
      window.location.href.includes('SignUp')
    ) {
      console.log('hide the stuff in header');
      setShowSignUp(true);
    }
    const numberUsers = axios.get(
      `${process.env.REACT_APP_API_BASE}/user/numberofusers`,
      {}
    );
    numberUsers.then((data) => setNumberOfUsers(data.data.totalUsersCount));
  }, [location]);

  React.useEffect(() => {
    if (location.pathname === '/' || location.pathname.startsWith('/postAdd')) {
      setShowLogin(user && auth ? false : true);
    } else {
      setShowLogin(false);
    }

    if (!location.pathname.startsWith('/search')) {
      setSearchTerm('');
    }
  }, [user, auth, location.pathname]);

  const [currentState, setState, stateHistory] =
    useLanguageHistory(currentLanguage);

  React.useEffect(() => {
    setCurrentLanguageDisplay(currentLanguage);
    translateLabels(currentLanguage);
    setState(currentLanguage);
  }, [currentLanguage]);

  React.useEffect(() => {
    if (location.pathname.startsWith('/search')) {
      translateSearch(currentState);
    }
  }, [currentState]);

  const translateSearch = async (language) => {
    console.log(stateHistory);

    const translatedTerm = await axios.post(
      `${process.env.REACT_APP_API_BASE}/admin/translateText`,
      {
        currentLanguage: language,
        ...(language === 'en' && {
          prevLanguage: stateHistory[stateHistory.length - 1],
        }),
        text: searchTerm,
        type: 'text',
        objectType: 'string',
      }
    );
    setSearchTerm(translatedTerm.data);

    dispatch(actions.searchActions.setSearchTerm(translatedTerm.data));

    translatedTerm.data && dispatch(actions.searchActions.setRunSearch(true));
  };

  const translateLabels = async (language) => {
    try {
      if (!language || language === 'en') {
        setQuickLinkLabel('About');
        setExploreLabel('Explore');
        setGetHelpLabel('Get Help');
        setGetEarnLabel('Earn Badges');
        setExtensionLabel('FP insight Browser Button');
        setGuidelinesLabel('Community Guidelines');
        setSettingsLabel('Settings');
        setLogoutLabel('Logout');
        setLoginLabelThree(
          'Up for an adventure? Check out our fun and interactive <a href="fpinsight-scavenger-hunt/english.html" target="_blank" rel="noreferrer" aria-label="scavenger hunt link">scavenger hunt</a> and earn your <a href="/Badges" rel="noreferrer" aria-label="badges link">Explorer badge</a> while learning more about FP insight!'
        );
        setAboutLabel('About FP insight');
        setContactLabel('Contact Us');

        setTrendingLabel('See what’s trending in FP/RH programs.');
        setQuicklyOneLabel('Quickly find relevant and timely information.');
        setQuicklyTwoLabel('FP insight');
        setQuicklyThreeLabel('makes it easy for you to monitor what’s');
        setQuicklyFourLabel('trending in FP/RH.');
        setSaveOneLabel('Save what you need.');
        setSaveTwoLabel(
          "Curate your own collections so it's easy for you to come back to the information you need, when you need it - even when you're offline."
        );
        setinspireOneLabel('See what inspires others.');
        setinspireTwoLabel('When you use');
        setinspireThreeLabel(
          'you’re contributing to a body of knowledge that benefits the whole FP/RH professional community.'
        );
        setQouteLabel(
          'FP insight is a game-changer for the FP/RH community.  I love it.'
        );
        setLearnMoreLabel('Learn More');
        setForYouBtnLabel('For You');
        setTrendingBtnLabel('Trending');
        setFollowingBtnLabel('Following');
        setAllUsersLabel('Browse All FP insight Users');
        setSearchHelperText(
          'To Search, Type a word or phrase and then press Enter'
        );
        setLoginText('Log in');
        setNewFeaturesRoadmapLabel('New Features Roadmap');
        setSignUpText('Sign up');
        setAllCollectionsLabel('Browse All Collections');
        setFpInsightText(
          'FP insight is simple, user-friendly, and responding to a real need in our field right now.'
        );
        setFpInsightNumberText('of your FP/RH colleagues are on FP insight.');
        setFpJoinLink('Join them today!');
        setScavengerHunt('FP insight Scavenger Hunt');
        setUserTestimonials('FP insight Member Stories');
        setFpInsightLinkMore('Learn More about FP insight');
        setExploreLink("Explore FP insight's features");
        setMemberStoriesLink('Read FP insight member stories');

        return;
      } else {
        const blueTextAreaText = await axios.post(
          `${process.env.REACT_APP_API_BASE}/admin/translateText`,
          {
            currentLanguage: currentLanguage,
            text: loginLabelThree,
            type: 'html',
          }
        );
        setLoginLabelThree(blueTextAreaText.data);

        const userCountText = await axios.post(
          `${process.env.REACT_APP_API_BASE}/admin/translateText`,
          {
            currentLanguage: currentLanguage,
            text: numberOfUsersLabel,
            type: 'html',
          }
        );
        setNumberOfUsersLabel(userCountText.data);

        const sections = await axios.post(
          `${process.env.REACT_APP_API_BASE}/admin/translateText`,
          {
            currentLanguage: currentLanguage,
            text: [
              quickLinkLabel,
              getHelpLabel,
              getEarnLabel,
              extensionLabel,
              guidelinesLabel,
              settingsLabel,
              logoutLabel,
              aboutLabel,
              contactLabel,
              trendingLabel,
              quicklyOneLabel,
              quicklyTwoLabel,
              quicklyThreeLabel,
              quicklyFourLabel,
              saveOneLabel,
              saveTwoLabel,
              inspireOneLabel,
              inspireTwoLabel,
              inspireThreeLabel,
              qouteLabel,
              learnMoreLabel,
              forYouBtnLabel,
              trendingBtnLabel,
              followingBtnLabel,
              allUsersLabel,
              searchHelperText,
              loginText,
              signUpText,
              allCollectionsLabel,
              newFeaturesRoadmapLabel,
              fpInsightText,
              fpInsightNumberText,
              fpJoinLink,
              scavengerHunt,
              userTestimonials,
              fpInsightLinkMore,
              exploreLink,
              memberStoriesLink,
              exploreLabel,
            ],
            type: 'text',
            objectType: 'array',
          }
        );

        if (sections.data && sections.data.length === 39) {
          setQuickLinkLabel(sections.data[0]);
          setGetHelpLabel(sections.data[1]);
          setGetEarnLabel(sections.data[2]);

          setExtensionLabel(sections.data[3]);
          setGuidelinesLabel(sections.data[4]);
          setSettingsLabel(sections.data[5]);
          setLogoutLabel(sections.data[6]);
          setAboutLabel(sections.data[7]);
          setContactLabel(sections.data[8]);

          setTrendingLabel(sections.data[9]);
          setQuicklyOneLabel(sections.data[10]);
          setQuicklyTwoLabel(sections.data[11]);
          setQuicklyThreeLabel(sections.data[12]);
          setQuicklyFourLabel(sections.data[13]);
          setSaveOneLabel(sections.data[14]);
          setSaveTwoLabel(sections.data[15]);
          setinspireOneLabel(sections.data[16]);
          setinspireTwoLabel(sections.data[17]);
          setinspireThreeLabel(sections.data[18]);

          setQouteLabel(sections.data[19]);
          setLearnMoreLabel(sections.data[20]);
          setForYouBtnLabel(sections.data[21]);
          setTrendingBtnLabel(sections.data[22]);
          setFollowingBtnLabel(sections.data[23]);
          setAllUsersLabel(sections.data[24]);
          setSearchHelperText(sections.data[25]);
          setLoginText(sections.data[26]);
          setSignUpText(sections.data[27]);
          setAllCollectionsLabel(sections.data[28]);

          setNewFeaturesRoadmapLabel(sections.data[29]);
          setFpInsightText(sections.data[30]);
          setFpInsightNumberText(sections.data[31]);
          setFpJoinLink(sections.data[32]);

          setScavengerHunt(sections.data[33]);
          setUserTestimonials(sections.data[34]);
          setFpInsightLinkMore(sections.data[35]);
          setExploreLink(sections.data[36]);
          setMemberStoriesLink(sections.data[37]);
          setExploreLabel(sections.data[38]);
        }
      }
    } catch (ex) {
      addToast(`There was an error translating the quick links ${ex.message}`, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 10000,
      });
    }
  };

  const doSearch = async () => {
    try {
      if (searchTerm === '') {
        dispatch(actions.searchActions.setSearching(false));
        return;
      }
      dispatch(actions.searchActions.setSearchResults(null));
      dispatch(actions.searchActions.setSearching(true));
      if (searchTerm && searchTerm.length > 0) {
        let response = null;
        history.push('/search');

        response = await axios.post(
          `${process.env.REACT_APP_API_BASE}/user/multiSearch`,
          { text: searchTerm, currentLanguage }
        );
        dispatch(actions.userActions.a11yMessage('Search request completed'));
        dispatch(actions.searchActions.setSearchResults(response.data.results));
        dispatch(actions.searchActions.setSearching(false));
        dispatch(actions.searchActions.setRunSearch(false));
      }
    } catch {
      addToast(`There was an error during the ${searchType} search`, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 10000,
      });
    }
  };

  const setSearchTerm = (val) => {
    dispatch(actions.searchActions.setSearchTerm(val));
  };

  const setSearchType = (eventKey) => {
    dispatch(actions.searchActions.setSearchType(eventKey));
  };

  const setCurrentLanguage = (eventKey) => {
    dispatch(actions.userActions.setLanguage(eventKey));
    setCurrentLanguageDisplay(eventKey);
    //translateLabels(eventKey);
  };

  const setCurrentLanguageDisplay = (lang) => {
    switch (lang) {
      case 'en':
        setCurrentLanguageLabel('English');
        break;
      case 'am':
        setCurrentLanguageLabel('አማርኛ');
        break;
      case 'ar':
        setCurrentLanguageLabel('عربي');
        break;
      case 'bn':
        setCurrentLanguageLabel('বাঙ্গালী');
        break;
      case 'fr':
        setCurrentLanguageLabel('Français');
        break;
      case 'ha':
        setCurrentLanguageLabel('هَرْشٜىٰن هَوْسَا');
        break;
      case 'hi':
        setCurrentLanguageLabel('हिंदी');
        break;
      case 'ig':
        setCurrentLanguageLabel('Igbo');
        break;
      case 'id':
        setCurrentLanguageLabel('Bahasa Indonesia');
        break;
      case 'km':
        setCurrentLanguageLabel('ខ្មែរ');
        break;
      case 'lo':
        setCurrentLanguageLabel('ພາສາລາວ');
        break;
      case 'ne':
        setCurrentLanguageLabel('नेपाली');
        break;
      case 'ps':
        setCurrentLanguageLabel('پښتو');
        break;
      case 'pt':
        setCurrentLanguageLabel('Português');
        break;
      case 'pa':
        setCurrentLanguageLabel('ਪੰਜਾਬੀ');
        break;
      case 'es':
        setCurrentLanguageLabel('Español');
        break;
      case 'sw':
        setCurrentLanguageLabel('Kiswahili');
        break;
      case 'th':
        setCurrentLanguageLabel('แบบไทย');
        break;
      case 'ur':
        setCurrentLanguageLabel('اردو');
        break;
      case 'vi':
        setCurrentLanguageLabel('Tiếng Việt');
        break;
      case 'yo':
        setCurrentLanguageLabel('Èdè Yorùbá');
        break;
      default:
        setCurrentLanguageLabel('English');
        break;
    }
  };

  const searchAllUsers = async () => {
    setSearchTerm('Search All Users');
    dispatch(actions.searchActions.setSearchType('people'));
    dispatch(actions.searchActions.setRunSearch(true));
  };

  const clearAuthenticated = async () => {
    dispatch(actions.resetActions.resetAll());
    history.push('/');
  };

  React.useEffect(() => {
    if (runSearch) doSearch();
  }, [runSearch]);

  return isOnline ? (
    <header aria-label="site header" role="banner">
      <SkipNavLink className="btn btn-secondary">Skip Navigation</SkipNavLink>
      <Headroom>
        <Navbar bg="white" expand="lg">
          <Navbar.Brand>
            <Link to={`/`}>
              <img
                src={fpLogo}
                alt="FP insights, powered by Knowledge Success"
                className="header-logo"
              />
              <span className="sr-only">dashboard</span>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <label className="sr-only" htmlFor="search">
              Search This Site
            </label>

            <InputGroup>
              <FormControl
                id="search"
                placeholder={searchHelperText}
                aria-label="Search"
                aria-describedby="search-helper"
                className="form-control-new"
                type="input"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    dispatch(actions.searchActions.setRunSearch(true));
                  }
                }}
              />
              <div id="search-helper" hidden className="password-helper-text">
                {searchHelperText}
              </div>
            </InputGroup>

            <div className="d-flex mobile-margin">
              <Dropdown>
                <Dropdown.Toggle
                  variant="btn btn-language"
                  className="mr-sm-2 ml-sm-2 mobile-languages"
                  id="quicklinks-dropdown"
                >
                  {quickLinkLabel}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="https://kmhelpdesk.knowledgesuccess.org/fp-insight-virtual-helpdesk"
                    target="_blank"
                  >
                    {getHelpLabel}
                  </Dropdown.Item>
                  <Dropdown.Item href="/About">{aboutLabel}</Dropdown.Item>
                  <Dropdown.Item href="memberstories">
                    {userTestimonials}
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="/fpinsight-scavenger-hunt/english.html"
                    target="_blank"
                  >
                    {scavengerHunt}
                  </Dropdown.Item>
                  <Dropdown.Item href="/browse">{allUsersLabel}</Dropdown.Item>
                  <Dropdown.Item
                    href="https://knowledgesuccess.org/wp-content/uploads/2023/10/FP-Insight-roadmap-English-2023.pdf"
                    target="_blank"
                  >
                    {newFeaturesRoadmapLabel}
                  </Dropdown.Item>
                  <Dropdown.Item href="/Guidelines">
                    {guidelinesLabel}
                  </Dropdown.Item>
                  <Dropdown.Item href="/Contact">{contactLabel}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  variant="btn btn-language"
                  className="mr-sm-2 ml-sm-2 mobile-languages"
                  id="quicklinks-dropdown"
                >
                  {exploreLabel}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/browsecollections">
                    {allCollectionsLabel}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  variant="btn btn-language"
                  className="mr-sm-2 ml-sm-2 mobile-languages"
                  id="language-dropdown"
                >
                  {currentLanguageLabel}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey="en"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'en'}
                  >
                    English
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="am"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'am'}
                  >
                    አማርኛ
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="ar"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'ar'}
                  >
                    عربي
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="bn"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'bn'}
                  >
                    বাঙ্গালী
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="fr"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'fr'}
                  >
                    Français
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="ha"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'ha'}
                  >
                    هَرْشٜىٰن هَوْسَا
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="hi"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'hi'}
                  >
                    हिंदी
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="ig"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'ig'}
                  >
                    Igbo
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="id"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'id'}
                  >
                    Bahasa Indonesia
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="km"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'km'}
                  >
                    ខ្មែរ
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="lo"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'lo'}
                  >
                    ພາສາລາວ
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="ne"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'ne'}
                  >
                    नेपाली
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="ps"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'ps'}
                  >
                    پښتو
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="pt"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'pt'}
                  >
                    Português
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="pa"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'pa'}
                  >
                    ਪੰਜਾਬੀ
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="es"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'es'}
                  >
                    Español
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="sw"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'sw'}
                  >
                    Kiswahili
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="th"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'th'}
                  >
                    แบบไทย
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="ur"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'ur'}
                  >
                    اردو
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="vi"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'vi'}
                  >
                    Tiếng Việt
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="yo"
                    onSelect={setCurrentLanguage}
                    active={currentLanguage === 'yo'}
                  >
                    Èdè Yorùbá
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="d-flex mobile-margin">
                {!user && (
                  <div className="mr-sm-2 ml-sm-2">
                    <button
                      className="btn btn-primary btn-block"
                      onClick={() => history.push('/?login=true')}
                    >
                      {loginText}
                    </button>
                  </div>
                )}
                {!user && (
                  <div className="">
                    <button
                      className="btn btn-signup btn-block"
                      onClick={() => history.push('/?login=false')}
                    >
                      {signUpText}
                    </button>
                  </div>
                )}
              </div>
              {user && auth ? <Notifications /> : null}
              {user && auth ? (
                <div className="ml-3" style={{ cursor: 'pointer' }}>
                  <Dropdown>
                    <Dropdown.Toggle as={Avatar} user={user} />

                    <Dropdown.Menu className="dropdown-menu">
                      <Dropdown.Item>
                        <Link
                          aria-label={`${user.firstName} ${user.lastName}'s profile`}
                          to={`/profile/${user.slug}`}
                          className="mobile-avatar"
                        >
                          View Profile and Collections
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item href="/Badges">
                        {getEarnLabel}
                      </Dropdown.Item>
                      {user && auth && (
                        <Dropdown.Item href="/Settings">
                          {settingsLabel}
                        </Dropdown.Item>
                      )}
                      {user && auth && (
                        <Dropdown.Item onSelect={clearAuthenticated}>
                          {logoutLabel}
                        </Dropdown.Item>
                      )}
                      {user && auth && user.status == '1' && (
                        <Dropdown.Item
                          href={process.env.REACT_APP_ADMIN_URL}
                          target="_blank"
                        >
                          FP insight Admin
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : null}
            </div>
          </Navbar.Collapse>
        </Navbar>

        {user && auth && !showSignUp && (
          <div className="log-in-user-banner">{parse(loginLabelThree)}</div>
        )}
        {user && auth && !showSignUp ? (
          <div className="row d-flex justify-content-center">
            {/* <SkipNavContent /> */}
            <div className="mt-1 mb-1">
              <button
                className={`btn btn-primary btn-for-you mr-2 mb-2 ${
                  postType === 'MyFeed' ? 'active' : ''
                }`}
                onClick={() => history.push('/foryou')}
              >
                <FontAwesomeIcon
                  icon={['fal', 'lightbulb']}
                  size="lg"
                  className="mr-1"
                />{' '}
                {forYouBtnLabel}
              </button>
              <button
                className={`btn btn-primary btn-trending mr-2 mb-2 ${
                  postType === 'Trending' ? 'active' : ''
                }`}
                onClick={() => history.push('/')}
              >
                <FontAwesomeIcon
                  icon={['fal', 'bolt']}
                  size="lg"
                  className="mr-1"
                />{' '}
                {trendingBtnLabel}
              </button>
              <button
                className={`btn btn-primary btn-following mb-2 ${
                  postType === 'Following' ? 'active' : ''
                }`}
                onClick={() => history.push('/followingFeed')}
              >
                <FontAwesomeIcon
                  icon={['fal', 'users']}
                  size="lg"
                  className="mr-1"
                />{' '}
                {followingBtnLabel}
              </button>
            </div>
          </div>
        ) : (
          <div>{!showSignUp && <InfoBanner />}</div>
        )}
      </Headroom>
      {showLogin && (
        <div className="banner-wrapper-home">
          <section className="container banner">
            <div className={showSignUp ? null : 'row'}>
              <img
                alt="Illustration about FP insight"
                src={line_hero}
                className="line_hero-banner img-fluid"
              />
              <img
                alt="Illustration about FP insight"
                src={line_hero_right}
                className="line_hero_right-banner img-fluid"
              />
              {!showSignUp && (
                <div className="col-lg-7">
                  <h1 className="trending-hd">{trendingLabel}</h1>
                  <div className="row mt-3">
                    <div className="col-lg-2 col-sm-3 col-4 mobile-center">
                      <span className="fa-stack fa-3x">
                        <FontAwesomeIcon
                          icon="circle"
                          color="#00A0DD"
                          className="fa-stack-2x"
                        />
                        <FontAwesomeIcon
                          icon={['fal', 'lightbulb-on']}
                          className="fa-stack-1x"
                        />
                      </span>
                    </div>
                    <div className="col-lg-10 col-sm-9 col-8">
                      <h2>{quicklyOneLabel}</h2>
                      <p className="mb-0">
                        <b>{quicklyTwoLabel}</b> {quicklyThreeLabel}{' '}
                        {quicklyFourLabel}
                      </p>
                      <p>
                        <a target="_blank" href="/About">
                          {fpInsightLinkMore}{' '}
                        </a>
                        <FontAwesomeIcon icon="search" className="fa-1x ml-1" />
                      </p>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-2 col-sm-3 col-4">
                      <span className="fa-stack fa-3x">
                        <FontAwesomeIcon
                          icon="circle"
                          color="#F9AA21"
                          className="fa-stack-2x"
                        />
                        <FontAwesomeIcon
                          icon={['fal', 'cloud-download']}
                          className="fa-stack-1x"
                        />
                      </span>
                    </div>
                    <div className="col-lg-10 col-sm-9 col-8">
                      <h2>{saveOneLabel}</h2>
                      <p className="mb-0">{saveTwoLabel}</p>
                      <p>
                        <a
                          target="_blank"
                          href="https://knowledgesuccess.org/wp-content/uploads/2023/10/FP-Insight-roadmap-English-2023.pdf"
                          rel="noreferrer"
                        >
                          {exploreLink}
                        </a>
                        <FontAwesomeIcon
                          icon="map-marked-alt"
                          className="fa-1x ml-2"
                        />
                      </p>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-2 col-sm-3 col-4">
                      <span className="fa-stack fa-3x">
                        <FontAwesomeIcon
                          icon="circle"
                          color="#FF4713"
                          className="fa-stack-2x"
                        />
                        <FontAwesomeIcon
                          icon={['fal', 'users']}
                          className="fa-stack-1x"
                        />
                      </span>
                    </div>
                    <div className="col-lg-10 col-sm-9 col-8">
                      <h2>{inspireOneLabel}</h2>
                      <p className="mb-0">
                        {inspireTwoLabel} <b>FP insight</b>, {inspireThreeLabel}
                      </p>
                      <p>
                        <a href="memberstories">{memberStoriesLink}</a>
                        <FontAwesomeIcon
                          icon="book-open"
                          className="fa-1x ml-2"
                        />
                      </p>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-6 col-12 mb-4 hd-quote">
                      <span>
                        "{qouteLabel}"<br />
                        <strong>- Ellen Starbird, USAID</strong>
                      </span>
                    </div>
                    <div className="col-lg-12 col-12 mb-4 ml-2 text-center">
                      <div
                        className="fp-insight-banner row"
                        id="fp-insight-banner"
                      >
                        <div className="col-12 text-center text-italic">
                          "{fpInsightText}"
                        </div>
                        <div className="col-12 text-center">
                          <img
                            className="img-fluid"
                            aria-hidden="true"
                            src="/fp_line.png"
                            alt=""
                          />
                        </div>
                        <div className="col-12 fp-number">
                          <span>
                            <a href="/browse" target="_blank">
                              {numberOfUsers}
                            </a>
                          </span>{' '}
                          {fpInsightNumberText}
                        </div>
                        <div className="col-12 fp-link">
                          <a href="/?login=false">{fpJoinLink}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {showSignUp && (
                <div className=" mr-5 ml-5">
                  <div className="col mr-5">
                    <div className="login-form-wrapper">
                      <Login />
                    </div>
                  </div>
                </div>
              )}
              {!showSignUp && (
                <div className="col-lg-5">
                  <div className="form-wrapper">
                    <Login />
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      )}
    </header>
  ) : (
    <header>
      <Navbar bg="white" expand="lg">
        <Navbar.Brand href="/">
          <img src={fpLogo} alt="FP insight" className="header-logo" />
        </Navbar.Brand>
      </Navbar>
    </header>
  );
};

export default Header;
